import React from "react";

class Services extends React.Component<any, any> {
    render() {
        return <div>
            <section id="services">

                <div className="overlay"></div>

                <div className="row section-intro">
                    <div className="col-twelve">

                        <h5>Services</h5>
                        <h1>What Can I Do For You?</h1>

                        <p className="lead">
                            Every customer has different needs. I create custom websites following theses desires.
                            I design, build & maintain.
                        </p>

                    </div>
                    <div className="row services-content">

                        <div className="services-list">

                            <div className="service col-four">

                                <span className="icon"><i className="icon-earth"></i></span>

                                <div className="service-content">

                                    <h3>Webdesign</h3>

                                    <p className="desc"></p>

                                </div>

                            </div>

                            <div className="service col-four">

                                <span className="icon"><i className="icon-window"></i></span>

                                <div className="service-content">

                                    <h3>Web Development</h3>

                                    <p className="desc"></p>

                                </div>

                            </div>

                            <div className="service col-four">

                                <span className="icon"><i className="icon-toggles"></i></span>

                                <div className="service-content">

                                    <h3>UI/UX Design</h3>

                                    <p className="desc"></p>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </section>
        </div>;
    }
}

export default Services