import React from 'react';
import './App.css';
import './style/base.css'
import './style/fonts.css'
import './style/main.css'
import './style/vendor.css'
import MainContent from "./components/mainContent";
import About from "./components/about"
import Services from "./components/services";
// import Resume from "./components/resume";

class App extends React.Component<any, any>{
  render() {
    return <div className="App">
      <MainContent/>
      <About/>
      {/*<Resume/>*/}
      <Services/>
    </div>;
  }
}

export default App;
