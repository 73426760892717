import React from "react";

class MainContent extends React.Component<any, any> {
    render() {
        return <div>
            <section id="intro">
                <div className="intro-overlay"></div>
                <div className="intro-content">
                    <div className="row">

                        <div className="col-twelve">

                            <h5>Hi !</h5>
                            <h1>I'm Thomas Berlier</h1>

                            <p className="intro-position">
                                <span>Front-end Developer</span>
                                <span>Web Designer</span>
                            </p>
                        </div>

                    </div>
                </div>

                <ul className="intro-social">
                    <li>
                        <a href="https://www.linkedin.com/in/thomasberlier/">
                            <i className="fa fa-linkedin"/>
                        </a>
                    </li>
                </ul>
            </section>
        </div>;
    }
}

export default MainContent