import React from "react";

class About extends React.Component<any, any> {
    render() {
        return <div>
            <section id="about">

                <div className="row section-intro">
                    <div className="col-twelve">

                        <h5>About</h5>
                        <h1>Let me introduce myself.</h1>

                        <div className="intro-info">
                            {/*<img src="images/profile-pic.jpg" alt="Profile"/>*/}
                            <p className="lead">
                                I'm Thomas, from Mons. I'm a web designer & front-end developer since 2014.
                                I'm passionate about technologies and always seeking for improve creativity and user's experience.
                            </p>
                            <p>
                                If I'm not building a new website, be sure that you can find me in a climbing gym.
                                Or in a metal music festival
                            </p>
                        </div>

                    </div>
                </div>

                <div className="row about-content">

                    <div>

                        <h3>Skills</h3>
                        <p>
                            With a bachelor degree in graphical technics, I have several strings to one's bow.
                            In one hand, I got the web side with its different languages and a touch of backend development.
                            In the other hand, I have the skills to create an attractive eye catching design.
                        </p>

                        <ul className="skill-bars col-six tab-full">
                            <li>
                                <div className="progress"></div>
                                <strong>HTML5</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>CSS3 & Tailwind CSS</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>Javascript/Typescript & VueJs</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>AWS CDK</strong>
                            </li>
                        </ul>
                        <ul className="skill-bars col-six tab-full">
                            <li>
                                <div className="progress"></div>
                                <strong>Illustrator</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>Photoshop</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>InDesign</strong>
                            </li>
                            <li>
                                <div className="progress"></div>
                                <strong>Adobe XD</strong>
                            </li>
                        </ul>

                    </div>

                </div>

                {/*<div className="row button-section">
                    <div className="col-twelve">
                        <a href="#" title="Download CV" className="button button-primary">Download CV</a>
                    </div>
                </div>*/}

            </section>
        </div>;
    }
}

export default About